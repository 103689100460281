import { c as _c } from "react/compiler-runtime";
import { jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { SvgIconContext } from "./SvgIconContext";
import * as style from "./SvgIcon.module.css";
const SvgIcon = t0 => {
  const $ = _c(11);
  const {
    src,
    alt: t1,
    className
  } = t0;
  const alt = t1 === undefined ? "" : t1;
  const [previousSrc, setPreviousSrc] = useState(src);
  const [previousAlt, setPreviousAlt] = useState(alt);
  const [svg, setSvg] = useState("");
  const {
    icons,
    fetchIcon
  } = useContext(SvgIconContext);
  let t2;
  if ($[0] !== alt || $[1] !== fetchIcon || $[2] !== icons || $[3] !== previousAlt || $[4] !== previousSrc || $[5] !== src || $[6] !== svg) {
    t2 = () => {
      if (!svg.trim() || src !== previousSrc || alt !== previousAlt) {
        const altText = !alt?.trim() ? "noalt" : alt;
        const icon = icons.find(icon2 => icon2.src === src && icon2.alt === altText);
        setSvg(icon?.icon ?? fetchIcon(src, altText));
        setPreviousSrc(src);
        setPreviousAlt(altText);
      }
    };
    $[0] = alt;
    $[1] = fetchIcon;
    $[2] = icons;
    $[3] = previousAlt;
    $[4] = previousSrc;
    $[5] = src;
    $[6] = svg;
    $[7] = t2;
  } else {
    t2 = $[7];
  }
  useEffect(t2);
  let t3;
  if ($[8] !== className || $[9] !== svg) {
    t3 = jsx("div", {
      className: classNames(style.icon, className),
      dangerouslySetInnerHTML: {
        __html: svg
      }
    });
    $[8] = className;
    $[9] = svg;
    $[10] = t3;
  } else {
    t3 = $[10];
  }
  return t3;
};
export default SvgIcon;