import { jsx } from "react/jsx-runtime";
import useServerSentEvents from "../useServerSentEvents";
import { createContext, useRef, useState } from "react";
export const TopBarContext = createContext(null);
export function TopBarProvider({
  value,
  isLogin,
  children
}) {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(value.isMaintenanceMode);
  const [areJobsRunning, setAreJobsRunning] = useState(value.areJobsRunning);
  const [runningJobs, setRunningJobs] = useState(value.runningJobs);
  const [queuedJobs, setQueuedJobs] = useState(value.queuedJobs);
  const queueEventTarget = useRef(new EventTarget());
  useServerSentEvents("maintenancemodeupdated", event => {
    setIsMaintenanceMode(event.detail.isMaintenanceMode);
    setAreJobsRunning(event.detail.areJobsRunning);
  });
  useServerSentEvents("queueupdated", event_0 => {
    setRunningJobs(event_0.detail.runningJobs);
    setQueuedJobs(event_0.detail.queuedJobs);
    if (event_0.detail.runningJobs.some(job => !runningJobs.some(j => j.id === job.id))) {
      queueEventTarget.current.dispatchEvent(new Event("newrunningjob"));
    }
    if (event_0.detail.queuedJobs.some(job_0 => !queuedJobs.some(j_0 => j_0.id === job_0.id))) {
      queueEventTarget.current.dispatchEvent(new Event("newqueuedjob"));
    }
  });
  return /* @__PURE__ */jsx(TopBarContext.Provider, {
    value: {
      ...value,
      isMaintenanceMode,
      areJobsRunning,
      runningJobs,
      queuedJobs,
      queueEventTarget
    },
    children
  });
}