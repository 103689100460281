import { Fragment, jsx } from "react/jsx-runtime";
import parse from "html-react-parser";
import IntlMessageFormat from "intl-messageformat";
export function getMessage(code, args = [], pluralArgs = []) {
  if (pluralArgs.length > 0) {
    return `${new IntlMessageFormat($i18n.m(code, args)).format(Object.fromEntries(pluralArgs.map((arg, index) => [`plural${index}`, arg])))}`;
  }
  return $i18n.m(code, args);
}
export const Message = ({
  code,
  args = [],
  pluralArgs = [],
  parseHtml = false
}) => {
  if (parseHtml) return /* @__PURE__ */jsx(Fragment, {
    children: parse(getMessage(code, args, pluralArgs))
  });
  return /* @__PURE__ */jsx(Fragment, {
    children: getMessage(code, args, pluralArgs)
  });
};