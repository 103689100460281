import { c as _c } from "react/compiler-runtime";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { TopBarContext } from "./TopBarContext";
import Button from "../Button/Button";
import SvgIcon from "../SvgIcon/SvgIcon";
import useClickOutsideToClose from "../useClickOutsideToClose";
import { logDefaultHttpError } from "../LogUtils";
import * as i18n from "../i18n";
import * as style from "./TopBar.module.css";
import { useContext, useEffect, useState } from "react";
export default function TopBar(t0) {
  const $ = _c(33);
  const {
    isLogin
  } = t0;
  const {
    latestVersion,
    latestVersionLink,
    isMaintenanceMode,
    areJobsRunning,
    showLicenseExpired,
    showLicenseWarn,
    runningJobs,
    queuedJobs,
    queueEventTarget,
    user,
    version,
    companyName
  } = useContext(TopBarContext);
  const [showQueue, setShowQueue] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const queueRef = useClickOutsideToClose(showQueue, setShowQueue);
  const userMenuRef = useClickOutsideToClose(showUserMenu, setShowUserMenu);
  const [animatePulseRunning, setAnimatePulseRunning] = useState(false);
  const [animatePulseQueued, setAnimatePulseQueued] = useState(false);
  let t1;
  if ($[0] !== queueEventTarget.current) {
    t1 = () => {
      const handleNewRunningJob = function handleNewRunningJob() {
        setAnimatePulseRunning(true);
        setTimeout(() => setAnimatePulseRunning(false), 600);
      };
      queueEventTarget.current.addEventListener("newrunningjob", handleNewRunningJob);
      return () => queueEventTarget.current.removeEventListener("newrunningjob", handleNewRunningJob);
    };
    $[0] = queueEventTarget.current;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== animatePulseRunning) {
    t2 = [animatePulseRunning];
    $[2] = animatePulseRunning;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  useEffect(t1, t2);
  let t3;
  if ($[4] !== queueEventTarget.current) {
    t3 = () => {
      const handleNewQueuedJob = function handleNewQueuedJob() {
        setAnimatePulseQueued(true);
        setTimeout(() => setAnimatePulseQueued(false), 600);
      };
      queueEventTarget.current.addEventListener("newqueuedjob", handleNewQueuedJob);
      return () => queueEventTarget.current.removeEventListener("newqueuedjob", handleNewQueuedJob);
    };
    $[4] = queueEventTarget.current;
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  let t4;
  if ($[6] !== animatePulseQueued) {
    t4 = [animatePulseQueued];
    $[6] = animatePulseQueued;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  useEffect(t3, t4);
  let t5;
  if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = async function interruptRunningJob(job) {
      const url = `/command-center/queue/interruptJob/${job.id}`;
      const response = await fetch(url);
      if (!response.ok) {
        logDefaultHttpError(response.status, url);
        return;
      }
      const json = await response.json();
      if (json.status !== 200) {
        logDefaultHttpError(json.status, url, json.message);
        return;
      }
    };
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  const interruptRunningJob = t5;
  let t6;
  if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = async function removeJobFromQueue(job_0) {
      const url_0 = `/command-center/queue/removeJob/${job_0.id}`;
      const response_0 = await fetch(url_0);
      if (!response_0.ok) {
        logDefaultHttpError(response_0.status, url_0);
        return;
      }
      const json_0 = await response_0.json();
      if (json_0.status !== 200) {
        logDefaultHttpError(json_0.status, url_0, json_0.message);
        return;
      }
    };
    $[9] = t6;
  } else {
    t6 = $[9];
  }
  const removeJobFromQueue = t6;
  let t7;
  if ($[10] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = async function clearQueue() {
      const response_1 = await fetch("/command-center/queue/clear");
      if (!response_1.ok) {
        logDefaultHttpError(response_1.status, "/command-center/queue/clear");
        return;
      }
      const json_1 = await response_1.json();
      if (json_1.status !== 200) {
        logDefaultHttpError(json_1.status, "/command-center/queue/clear", json_1.message);
        return;
      }
    };
    $[10] = t7;
  } else {
    t7 = $[10];
  }
  const clearQueue = t7;
  let t8;
  if ($[11] !== animatePulseQueued || $[12] !== animatePulseRunning || $[13] !== areJobsRunning || $[14] !== companyName || $[15] !== isLogin || $[16] !== isMaintenanceMode || $[17] !== latestVersion || $[18] !== latestVersionLink || $[19] !== queueRef || $[20] !== queuedJobs || $[21] !== runningJobs || $[22] !== showLicenseExpired || $[23] !== showLicenseWarn || $[24] !== showQueue || $[25] !== showUserMenu || $[26] !== user || $[27] !== userMenuRef || $[28] !== version) {
    let t9;
    if ($[30] !== latestVersion || $[31] !== latestVersionLink) {
      t9 = latestVersion && jsx(Button, {
        color: "tintDark",
        padding: "none",
        href: latestVersionLink,
        target: "_blank",
        children: jsx("div", {
          className: style.latestVersionMessage,
          children: jsx(i18n.Message, {
            code: "later.version.available",
            args: [latestVersion]
          })
        })
      });
      $[30] = latestVersion;
      $[31] = latestVersionLink;
      $[32] = t9;
    } else {
      t9 = $[32];
    }
    t8 = jsxs("div", {
      className: style.topBar,
      children: [jsxs(Button, {
        color: "tintDark",
        padding: "small",
        href: "/command-center",
        children: [jsx(SvgIcon, {
          src: "icons/cc-logo-color.svg",
          className: style.logo
        }), jsx("h1", {
          children: "Command Center"
        })]
      }), jsxs("div", {
        className: style.itemGroup,
        children: [t9, !isLogin && jsxs(Fragment, {
          children: [isMaintenanceMode && jsx("div", {
            className: style.maintenanceModeMessage,
            children: jsx(i18n.Message, {
              code: areJobsRunning ? "system.entering.maintenance.mode" : "system.in.maintenance.mode"
            })
          }), jsx(Button, {
            color: "tintDark",
            padding: "none",
            title: i18n.getMessage(showLicenseExpired ? "settings.expired.tooltip" : showLicenseWarn ? "settings.warn.tooltip" : "settings.tooltip"),
            className: showLicenseExpired ? style.expiredSettingsButton : showLicenseWarn ? style.warnSettingsButton : "",
            href: "/command-center/settings",
            children: jsx(SvgIcon, {
              src: "icons/settings.svg",
              alt: i18n.getMessage(showLicenseExpired ? "settings.expired.tooltip" : showLicenseWarn ? "settings.warn.tooltip" : "settings.tooltip")
            })
          }), jsxs("div", {
            className: style.queueButtonContainer,
            ref: queueRef,
            children: [animatePulseRunning && jsx("div", {
              className: style.pulseRunning
            }), animatePulseQueued && jsx("div", {
              className: style.pulseQueued
            }), jsx(Button, {
              color: "tintDark",
              padding: "none",
              title: i18n.getMessage("queue.and.running.jobs.tooltip"),
              onClick: () => setShowQueue(_temp),
              children: jsx(SvgIcon, {
                src: "icons/queue.svg",
                alt: i18n.getMessage("queue.and.running.jobs.tooltip"),
                className: `${queuedJobs?.length > 1 ? style.multipleQueuedJobs : queuedJobs?.length > 0 ? style.singleQueuedJob : ""} ${runningJobs?.length > 0 ? style.runningJobs : ""}`
              })
            })]
          }), jsxs("div", {
            ref: queueRef,
            className: `${style.queue} ${showQueue ? "" : style.hidden} hasScrollBar`,
            children: [jsxs("div", {
              className: style.queueGroup,
              children: [jsx("h3", {
                children: jsx(i18n.Message, {
                  code: "running.jobs.title",
                  args: [runningJobs?.length ?? 0]
                })
              }), jsx("div", {
                className: style.queueList,
                children: runningJobs?.length > 0 ? runningJobs.map(job_1 => jsxs("div", {
                  className: style.runningJobItem,
                  children: [jsx(SvgIcon, {
                    src: job_1.type.icon.src,
                    className: style.jobIcon
                  }), jsx("span", {
                    children: jsx(i18n.Message, {
                      code: job_1.type.code,
                      args: [job_1.projectId > 0 ? job_1.projectName : job_1.tmsId > 0 ? job_1.tmsName : job_1.xtmProjectName?.trim() ? job_1.xtmProjectName : "ERROR"]
                    })
                  }), jsx("div", {
                    children: jsx(Button, {
                      color: "tintDark",
                      size: "small",
                      padding: "none",
                      className: style.hoverButton,
                      href: !job_1.type.isSystem ? `/command-center/project/showProjectJobLog/${job_1.projectId}` : job_1.type.value === "XTM_CALLBACK" ? "/command-center/tmsConnection/showXtmCallbackJobLog" : job_1.type.value === "CHECK_SEND" ? "/command-center/tmsConnection/showCheckSendJobLog" : "/command-center/tmsConnection/showCheckImportJobLog",
                      children: jsx(SvgIcon, {
                        src: "icons/logs.svg",
                        alt: i18n.getMessage("view.log.from.queue")
                      })
                    })
                  }), jsx("div", {
                    children: user.role.isAdminOrManager && jsx(Button, {
                      color: "tintDark",
                      size: "small",
                      padding: "none",
                      className: style.hoverButton,
                      title: i18n.getMessage("kill.running.job"),
                      onClick: () => interruptRunningJob(job_1),
                      children: jsx(SvgIcon, {
                        src: "icons/delete.svg",
                        alt: i18n.getMessage("kill.running.job")
                      })
                    })
                  })]
                }, job_1.id)) : jsx("p", {
                  className: "color-fg2",
                  children: jsx(i18n.Message, {
                    code: "no.running.jobs"
                  })
                })
              })]
            }), jsxs("div", {
              className: style.queueGroup,
              children: [jsx("h3", {
                children: jsx(i18n.Message, {
                  code: "queued.jobs.title",
                  args: [queuedJobs?.length ?? 0]
                })
              }), queuedJobs?.length > 0 && user.role.isAdminOrManager && jsx(Button, {
                color: "tintDark",
                size: "small",
                padding: "none",
                className: style.clearQueueButton,
                title: i18n.getMessage("clear.queued.jobs"),
                onClick: clearQueue,
                children: jsx(SvgIcon, {
                  src: "icons/delete.svg",
                  alt: i18n.getMessage("clear.queued.jobs")
                })
              }), jsx("div", {
                className: style.queueList,
                children: queuedJobs?.length > 0 ? queuedJobs.map(job_2 => jsxs("div", {
                  className: style.queuedJobItem,
                  children: [jsx(SvgIcon, {
                    src: job_2.type.icon.src,
                    className: style.jobIcon
                  }), jsx("span", {
                    className: style.jobMessageArea,
                    children: jsx(i18n.Message, {
                      code: job_2.type.code,
                      args: [job_2.projectId > 0 ? job_2.projectName : job_2.tmsId > 0 ? job_2.tmsName : job_2.xtmProjectName?.trim() ? job_2.xtmProjectName : "ERROR"]
                    })
                  }), jsx("div", {
                    children: user.role.isAdminOrManager && jsx(Button, {
                      color: "tintDark",
                      size: "small",
                      padding: "none",
                      className: style.hoverButton,
                      title: i18n.getMessage("delete.job.from.queue"),
                      onClick: () => removeJobFromQueue(job_2),
                      children: jsx(SvgIcon, {
                        src: "icons/delete.svg",
                        alt: i18n.getMessage("delete.job.from.queue")
                      })
                    })
                  })]
                }, job_2.id)) : jsx("p", {
                  className: "color-fg2",
                  children: jsx(i18n.Message, {
                    code: "no.queued.jobs"
                  })
                })
              })]
            })]
          }), jsx("div", {
            ref: userMenuRef,
            children: jsxs(Button, {
              color: "tintDark",
              padding: "small",
              className: style.userMenuButton,
              onClick: () => setShowUserMenu(_temp2),
              children: [jsx("span", {
                className: style.usernameFlag,
                children: user.username
              }), jsx("img", {
                className: style.smallAvatar,
                src: "/command-center/assets/default-usericon.png",
                role: "presentation"
              })]
            })
          }), jsxs("div", {
            ref: userMenuRef,
            className: `${style.userMenu} ${showUserMenu ? "" : style.hidden} hasScrollBar`,
            children: [jsxs("div", {
              className: style.iconNameRoleContainer,
              children: [jsx("img", {
                className: style.bigAvatar,
                src: "/command-center/assets/default-usericon.png",
                role: "presentation"
              }), jsxs("div", {
                className: style.nameRoleContainer,
                children: [jsx("span", {
                  className: style.username,
                  children: user.username
                }), jsx("span", {
                  children: jsx(i18n.Message, {
                    code: user.role.code
                  })
                })]
              })]
            }), jsx(Button, {
              padding: "small",
              href: `/command-center/user/edit/${user.id}`,
              children: jsx(i18n.Message, {
                code: "default.button.edit.user.label"
              })
            }), jsx(Button, {
              filled: true,
              padding: "small",
              href: "/command-center/logout",
              children: jsx(i18n.Message, {
                code: "log.out"
              })
            }), jsxs("div", {
              className: style.versionAndCompanyNameContainer,
              children: [jsx("div", {
                className: style.version,
                children: jsx(i18n.Message, {
                  code: "commandcenter.verbose.version",
                  args: [version]
                })
              }), jsx("div", {
                className: style.companyName,
                children: jsx(i18n.Message, {
                  code: "company.name.message",
                  args: [companyName]
                })
              })]
            })]
          })]
        })]
      })]
    });
    $[11] = animatePulseQueued;
    $[12] = animatePulseRunning;
    $[13] = areJobsRunning;
    $[14] = companyName;
    $[15] = isLogin;
    $[16] = isMaintenanceMode;
    $[17] = latestVersion;
    $[18] = latestVersionLink;
    $[19] = queueRef;
    $[20] = queuedJobs;
    $[21] = runningJobs;
    $[22] = showLicenseExpired;
    $[23] = showLicenseWarn;
    $[24] = showQueue;
    $[25] = showUserMenu;
    $[26] = user;
    $[27] = userMenuRef;
    $[28] = version;
    $[29] = t8;
  } else {
    t8 = $[29];
  }
  return t8;
}
function _temp2(value_0) {
  return !value_0;
}
function _temp(value) {
  return !value;
}