.button {
    transition: var(--transition-fast);

    display: inline-flex;
    justify-content: center;
    align-items: center;

    font-size: 1rem;
    font-family: inherit;

    min-width: fit-content;
    border-style: solid;
    box-shadow: var(--dimension-1);
    box-sizing: border-box;
    padding-block-start: 0;
    padding-block-end: 0;

    white-space: nowrap;

    text-decoration: none;
}

.button:disabled {
    opacity: 0.5;
}

.button:hover:not(:disabled) {
    box-shadow: var(--dimension-2);
}

.button:disabled {
    box-shadow: none;
}

.large {
    height: var(--block-large);
    border-radius: var(--radius-loose);
    border-width: var(--border-thick);
}

.medium {
    height: var(--block-medium);
    border-radius: var(--radius-medium);
    border-width: var(--border-medium);
}

.small {
    height: var(--block-small);
    border-radius: var(--radius-tight);
    border-width: var(--border-thin);
}

.largePadding {
    padding-inline-start: var(--padding-large);
    padding-inline-end: var(--padding-large);
}

.mediumPadding {
    padding-inline-start: var(--padding-medium);
    padding-inline-end: var(--padding-medium);
}

.smallPadding {
    padding-inline-start: var(--padding-small);
    padding-inline-end: var(--padding-small);
}

.noPadding {
    padding-inline-start: 0;
    padding-inline-end: 0;
}

.bg {
    border-color: var(--color-bg1);
    background-color: transparent;
    color: var(--color-bg1);
}
.bg.filled {
    background-color: var(--color-bg1);
    color: var(--color-a0);
}
.bg:hover:not(:disabled),
.bg:focus-visible:not(:disabled) {
    border-color: var(--color-bg0);
    background-color: var(--color-bg0);
    color: var(--color-a1);
}

.fg {
    border-color: var(--color-fg2);
    background-color: transparent;
    color: var(--color-fg2);
}
.fg.filled {
    background-color: var(--color-fg2);
    color: var(--color-bg2);
}
.fg:hover:not(:disabled),
.fg:focus-visible:not(:disabled) {
    border-color: var(--color-fg3);
    background-color: var(--color-fg3);
    color: var(--color-bg0);
}

.accentPrimary {
    border-color: var(--color-a0);
    background-color: transparent;
    color: var(--color-a0);
}
.accentPrimary.filled {
    background-color: var(--color-a0);
    color: var(--color-bg1);
}
.accentPrimary:hover:not(:disabled),
.accentPrimary:focus-visible:not(:disabled) {
    border-color: var(--color-a1);
    background-color: var(--color-a1);
    color: var(--color-bg0);
}

.accentSecondary {
    border-color: var(--color-b0);
    background-color: transparent;
    color: var(--color-a0);
}
.accentSecondary.filled {
    background-color: var(--color-b0);
}
.accentSecondary:hover:not(:disabled),
.accentSecondary:focus-visible:not(:disabled) {
    border-color: var(--color-b1);
    background-color: var(--color-b1);
    color: var(--color-a1);
}

.accentTertiary {
    border-color: var(--color-c0);
    background-color: transparent;
    color: var(--color-a0);
}
.accentTertiary.filled {
    background-color: var(--color-c0);
}
.accentTertiary:hover:not(:disabled),
.accentTertiary:focus-visible:not(:disabled) {
    border-color: var(--color-c1);
    background-color: var(--color-c1);
    color: var(--color-a1);
}

.red {
    border-color: var(--color-red);
    background-color: transparent;
    color: var(--color-dark-red);
}
.red.filled {
    background-color: var(--color-red);
    color: var(--color-bg1);
}
.red:hover:not(:disabled),
.red:focus-visible:not(:disabled) {
    border-color: var(--color-dark-red);
    background-color: var(--color-dark-red);
    color: var(--color-bg0);
}

.light,
.dark {
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    color: inherit;
}
a.light {
    color: var(--color-bg1);
}
a.dark {
    color: var(--color-fg1);
}
.light:hover:not(:disabled),
.light:focus-visible:not(:disabled),
.light.filled {
    background-color: var(--tint-light);
    box-shadow: none;
}
.dark:hover:not(:disabled),
.dark:focus-visible:not(:disabled),
.dark.filled {
    background-color: var(--tint-dark);
    box-shadow: none;
}
