import { c as _c } from "react/compiler-runtime";
import { useEffect, useRef } from "react";
export default function useClickOutsideToClose(open, setOpen) {
  const $ = _c(6);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = [];
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const refs = useRef(t0);
  let t1;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = element => {
      if (element) {
        refs.current.push(element);
      }
    };
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const assignRef = t1;
  let t2;
  let t3;
  if ($[2] !== open || $[3] !== setOpen) {
    t2 = () => {
      const handleWindowClick = function handleWindowClick(event) {
        if (!open) {
          return;
        }
        if (!(event.target instanceof Node) || !document.contains(event.target)) {
          return;
        }
        if (refs.current.some(ref => ref?.contains(event.target))) {
          return;
        }
        setOpen(false);
      };
      window.addEventListener("click", handleWindowClick);
      return () => window.removeEventListener("click", handleWindowClick);
    };
    t3 = [refs, open, setOpen];
    $[2] = open;
    $[3] = setOpen;
    $[4] = t2;
    $[5] = t3;
  } else {
    t2 = $[4];
    t3 = $[5];
  }
  useEffect(t2, t3);
  return assignRef;
}