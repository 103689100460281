import { c as _c } from "react/compiler-runtime";
import { useEffect } from "react";
const useServerSentEvents = (event, callback) => {
  const $ = _c(4);
  let t0;
  let t1;
  if ($[0] !== callback || $[1] !== event) {
    t0 = () => {
      if (window.location.href.includes("login")) {
        return;
      }
      ;
      try {
        SSE.addEventListener(event, callback);
      } catch (t2) {
        const error = t2;
        console.error("An error occurred when trying to set up server sent events. This is likely because no SSE Event Source object could be found, which is normal and to be expected on the login page.");
        console.error(error);
        return;
      }
      return () => SSE.removeEventListener(event, callback);
    };
    t1 = [event, callback];
    $[0] = callback;
    $[1] = event;
    $[2] = t0;
    $[3] = t1;
  } else {
    t0 = $[2];
    t1 = $[3];
  }
  useEffect(t0, t1);
};
export default useServerSentEvents;