import { jsx } from "react/jsx-runtime";
import * as style from "./Button.module.css";
import classNames from "classnames";
const sizeValues = ["large", "medium", "small"];
const paddingValues = ["default", "small", "medium", "large", "none"];
const tintDarkColorValues = ["dark", "tint-dark", "tintDark", "none"];
const tintLightColorValues = ["light", "tint-light", "tintLight"];
const redColorValues = ["red", "red-button", "redButton"];
const fgColorValues = ["fg", "foreground"];
const bgColorValues = ["bg", "background"];
const accentPrimaryColorValues = ["accent-a", "accentA", "accent-primary", "accentPrimary", "primary"];
const accentSecondaryColorValues = ["accent-b", "accentB", "accent-secondary", "accentSecondary", "secondary"];
const accentTertiaryColorValues = ["accent-c", "accentC", "accent-tertiary", "accentTertiary", "tertiary"];
const colorValues = [...tintDarkColorValues, ...tintLightColorValues, ...redColorValues, ...fgColorValues, ...bgColorValues, ...accentPrimaryColorValues, ...accentSecondaryColorValues, ...accentTertiaryColorValues];
const Button = ({
  size = "large",
  padding = "default",
  color = "primary",
  type = "button",
  filled = false,
  disabled = false,
  title,
  href,
  target,
  onClick,
  className,
  children
}) => {
  const classes = classNames(className, style.button, getClassNameForSize(size), getClassNameForPadding(padding, size), getClassNameForColor(color), {
    [style.filled]: filled
  });
  if (href && href?.trim() && !disabled) return /* @__PURE__ */jsx("a", {
    href,
    target,
    className: classes,
    title,
    children
  });
  return /* @__PURE__ */jsx("button", {
    className: classes,
    onClick,
    disabled,
    type,
    title,
    children
  });
};
export default Button;
function getClassNameForSize(size) {
  switch (size) {
    case "large":
      return style.large;
    case "medium":
      return style.medium;
    case "small":
      return style.small;
    default:
      return style.large;
  }
}
function getClassNameForPadding(padding, size) {
  switch (padding) {
    case "large":
      return style.largePadding;
    case "medium":
      return style.mediumPadding;
    case "small":
      return style.smallPadding;
    case "none":
      return style.noPadding;
    default:
      switch (size) {
        case "large":
          return style.largePadding;
        case "medium":
          return style.mediumPadding;
        case "small":
          return style.smallPadding;
        default:
          return style.largePadding;
      }
  }
}
function getClassNameForColor(color) {
  switch (true) {
    case tintDarkColorValues.includes(color):
      return style.dark;
    case tintLightColorValues.includes(color):
      return style.light;
    case redColorValues.includes(color):
      return style.red;
    case bgColorValues.includes(color):
      return style.bg;
    case fgColorValues.includes(color):
      return style.fg;
    case accentPrimaryColorValues.includes(color):
      return style.accentPrimary;
    case accentSecondaryColorValues.includes(color):
      return style.accentSecondary;
    case accentTertiaryColorValues.includes(color):
      return style.accentTertiary;
    default:
      return style.accentPrimary;
  }
}