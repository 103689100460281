import { c as _c } from "react/compiler-runtime";
import { jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from "react";
let pending = [];
let fetching = [];
let fetchSvgContentTimeoutId = null;
let numRequests = 0;
setInterval(() => numRequests = 0, 1e4);
const svgIconEventTarget = new EventTarget();
function requestIconsEqual(icon1, icon2) {
  return icon1.src === icon2.src && icon1.alt === icon2.alt;
}
function fetchSvgContent(src, alt) {
  if (pending.some(icon => icon.src === src && icon.alt === alt)) return;
  if (pending.length > 100) return;
  pending.push({
    src,
    alt
  });
  async function debouncedFetch() {
    if (pending.length === 0) return;
    if (numRequests > 50) return;
    numRequests++;
    const icons = pending.filter(pendingIcon => !fetching.some(fetchingIcon => requestIconsEqual(pendingIcon, fetchingIcon)));
    fetching = [...fetching, ...icons];
    const paths = encodeURIComponent(icons.map(icon => icon.src).join(","));
    const alts = encodeURIComponent(icons.map(icon => icon.alt).join(","));
    const url = `/command-center/utility/getIcons?paths=${paths}&alts=${alts}`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (!response.ok) {
      fetching = fetching.filter(fetchingIcon => !icons.some(icon => requestIconsEqual(icon, fetchingIcon)));
      console.error(`received non-ok response code ${response.status} from ${url}`);
      return;
    }
    const json = await response.json();
    if (json.status !== 200) {
      fetching = fetching.filter(fetchingIcon => !icons.some(icon => requestIconsEqual(icon, fetchingIcon)));
      console.error(`received non-ok response code ${json.status} from ${url}${json.message ? `, message ${json.message}` : ""}`);
      console.error(json);
      return;
    }
    const svgIconEvent = new CustomEvent("newicons", {
      detail: {
        icons: json.icons
      }
    });
    svgIconEventTarget.dispatchEvent(svgIconEvent);
    fetching = fetching.filter(fetchingIcon => !icons.some(icon => icon.src === fetchingIcon.src && icon.alt === fetchingIcon.alt));
    pending = pending.filter(pendingIcon => !icons.some(icon => icon.src === pendingIcon.src && icon.alt === pendingIcon.alt));
    fetchSvgContentTimeoutId = null;
  }
  if (fetchSvgContentTimeoutId !== null) {
    clearTimeout(fetchSvgContentTimeoutId);
  }
  fetchSvgContentTimeoutId = setTimeout(debouncedFetch, 75);
}
export const SvgIconContext = createContext({});
export const SvgIconProvider = t0 => {
  const $ = _c(8);
  const {
    children
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = [];
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const [icons, setIcons] = useState(t1);
  let t2;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = () => {
      const newIconEventHandler = function newIconEventHandler(event) {
        if (!event.detail?.icons) {
          return;
        }
        setIcons(icons2 => [...icons2, ...event.detail.icons]);
      };
      svgIconEventTarget.addEventListener("newicons", newIconEventHandler);
      return () => svgIconEventTarget.removeEventListener("newicons", newIconEventHandler);
    };
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] !== icons) {
    t3 = [icons];
    $[2] = icons;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  useEffect(t2, t3);
  let t4;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = function fetchIcon(src, alt) {
      fetchSvgContent(src, alt);
      return "";
    };
    $[4] = t4;
  } else {
    t4 = $[4];
  }
  const fetchIcon = t4;
  let t5;
  if ($[5] !== children || $[6] !== icons) {
    t5 = jsx(SvgIconContext.Provider, {
      value: {
        icons,
        fetchIcon
      },
      children
    });
    $[5] = children;
    $[6] = icons;
    $[7] = t5;
  } else {
    t5 = $[7];
  }
  return t5;
};